import React from 'react';
import './ListItem.scss';

class ListItem extends React.Component {
  render() {
    return (
      <a href={this.props.url} className="p-c-list-item">
        <div className="c-list-item">
          <hr />
          <div className="c-list-item__title">{this.props.listTitle}</div>
          <small className="c-list-item__caption">{this.props.publishedDate}</small>
          <hr />
        </div>
      </a>
    );
  }
}

export default ListItem;
