import React from 'react';
import CheckedImage from './checked.svg';
import UnCheckedImage from './unchecked.svg';

export const CheckListItem = (props) => {
  return (
    <span
      style={{
        fontSize: '0.6666666667rem',
        fontStyle: 'normal',
        lineHeight: '100%',
        letterSpacing: '.16px',
        color: 'var(--browned-crust)',
        width: '100%',
        height: '32px',
        display: 'flex',
      }}
    >
      <img
        src={props.checked ? CheckedImage : UnCheckedImage}
        style={{
          height: '24px',
          width: '24px',
          marginTop: '4px',
        }}
        alt="checkbox"
      />
      <span
        style={{
          paddingTop: '9px',
          paddingLeft: '8px',
        }}
      >
        {props.children}
      </span>
    </span>
  );
};
