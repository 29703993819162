import React from 'react';
import './Footer.scss';
import MailChimpForm from '../MailChimpForm';

class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <footer className="c-footer">
        <ul>
          <li>
            © {year} <a href="https://christopherandersson.com">Christopher Andersson</a>. All
            rights reserved.
          </li>
          <li>
            Follow <a href="https://twitter.com/designandpizza">@DesignPizza</a>
          </li>
          <li>
            <MailChimpForm
              formTitle="Subscribe to the mailing list"
              inputStyle={{
                backgroundColor: 'var(--ricotta)',
                height: '16px',
                padding: '8px',
                borderRadius: '2px',
                border: 'none',
                fontSize: '0.7619047619rem',
                outline: 'none',
                marginLeft: '8px',
              }}
              buttonStyle={{
                backgroundColor: 'var(--cheese)',
                height: '32px',
                padding: '8px',
                borderRadius: '2px',
                border: 'none',
                fontSize: '0.7619047619rem',
                textTransform: 'uppercase',
                outline: 'none',
                marginLeft: '8px',
                position: 'relative',
                top: '1px',
                cursor: 'pointer',
              }}
            />
          </li>
        </ul>
      </footer>
    );
  }
}

export default Footer;
