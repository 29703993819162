import React from 'react';
import './SmallHeader.css';
import logo from './images/104vh.png';
import { Link } from 'gatsby';

class SmallHeader extends React.Component {
  render() {
    return (
      <div className="c-small-header">
        <Link to={'/'}>
          <img src={logo} className="c-small-header__logo" alt="design pizza logo" />
        </Link>
      </div>
    );
  }
}

export default SmallHeader;
