import React from 'react';
import './MailChimpForm.scss';

class MailChimpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  render() {
    return (
      <div>
        <form
          action="https://nycode.us8.list-manage.com/subscribe/post?u=ecb050263d8d76b7d12caca00&amp;id=448f6fd4f3"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div className="mc-field-group">
            <label style={this.props.labelStyle}>
              {this.props.formTitle}
              <input
                type="email"
                value={this.state.value}
                onChange={this.handleChange}
                name="EMAIL"
                style={this.props.inputStyle}
                id="mce-EMAIL"
              />
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                style={this.props.buttonStyle}
              />
            </label>
            <div
              style={{
                position: 'absolute',
                left: '-5000px',
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_ecb050263d8d76b7d12caca00_448f6fd4f3"
                tabIndex="-1"
                value=""
                onChange={this.handleChange}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default MailChimpForm;
