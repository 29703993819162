import React from 'react';
import './Chip.scss';
import { Link } from 'gatsby';

class Chip extends React.Component {
  render() {
    return (
      <Link className="c-chip__link" to={this.props.chipUrl}>
        <div className="c-chip">{this.props.children}</div>
      </Link>
    );
  }
}

export default Chip;
