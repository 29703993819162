import React from 'react';

export const Citation = (props) => {
  // less opaque version of --cheese
  const cheese = 'rgba(255,190,11,.14)';
  return (
    <cite
      style={{
        display: 'block',
        fontSize: '0.7619047619rem',
        fontStyle: 'normal',
        lineHeight: '150%',
        letterSpacing: '.16px',
        color: 'var(--browned-crust)',
        backgroundColor: cheese,
        padding: '24px 40px',
        width: '100%',
        borderRadius: '4px',
        boxSizing: 'border-box',
      }}
    >
      {props.children}
    </cite>
  );
};
