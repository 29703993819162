import React from 'react';

export const ImageCaption = (props) => {
  // should be the same as --browned-crust with transparency
  const brownedCrust = 'rgba(75,66,55,.54)';
  return (
    <span
      style={{
        width: '100%',
        textAlign: 'center',
        display: 'block',
      }}
    >
      <cite
        style={{
          fontSize: '0.7619047619em',
          color: brownedCrust,
          fontStyle: 'normal',
        }}
      >
        {props.children}
      </cite>
    </span>
  );
};
