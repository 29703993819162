import React from 'react';
import Helmet from 'react-helmet';
import './prismjs-theme.css';

const Page = (props) => {
  return (
    <div>
      <Helmet title={props.title}>
        <link type="text/css" href="https://cloud.typography.com/7910356/6684192/css/fonts.css" />
      </Helmet>
      {props.children}
    </div>
  );
};

export default Page;
