import React from 'react';

export const IntroParagraph = (props) => {
  return (
    <span
      style={{
        fontSize: '1.2857142857rem',
        lineHeight: '150%',
        letterSpacing: '0',
      }}
    >
      {props.children}
    </span>
  );
};
